<template>
  <div>
    <loading v-if="loading" />
    <div v-else>
      <b-table
        ref="table"
        :data="sortedTerms"
        :mobile-cards="true"
        hoverable
        striped
        :detailed="true"
        :show-detail-icon="true"
        detail-key="_termId"
        class="has-vertical-align-middle"
      >
        <template slot="empty">
          <no-results
            title="No terms"
            :cta="{ label: 'Add product term' }"
            message="You don't have any terms on this plan."
            @click="addEditTerm()"
          />
        </template>

        <template slot-scope="props">
          <b-table-column label="">
            <status-label
              :status="props.row.isActive ? 'active' : 'discontinued'"
            />
          </b-table-column>

          <b-table-column field="name" label="Name" width="300">
            <span>{{ props.row.name }}</span>
          </b-table-column>

          <b-table-column label="Term length">
            <strong>{{ props.row.termLength }}</strong>
          </b-table-column>

          <b-table-column label="Total monthly">
            <strong>{{ monthlyTotal(props.row) }}</strong>
          </b-table-column>

          <b-table-column label="Total">
            <strong>{{ monthlyTotal(props.row) }}</strong>
          </b-table-column>

          <b-table-column label=" ">
            <button class="button" @click="addEditTerm(props.row)">Edit</button>
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <p>{{ props.row.description }}</p>
        </template>
      </b-table>
      <div v-if="sortedTerms.length > 0" class="has-margin-top-100">
        <button class="button is-dark is-outlined" @click="addEditTerm()">
          Add product term
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { collection, getDocs } from "@firebase/firestore";
export default {
  name: "ProductTermsTable",
  props: {
    productId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      productTerms: {},
      processing: false
    };
  },
  computed: {
    currency() {
      return this.$store.getters["user/currency"]();
    },
    sortedTerms() {
      return this.$_.orderBy(
        this.productTerms,
        ["isActive", "_productId", "termLength"],
        ["desc", "desc", "desc"]
      );
    }
  },
  created() {
    this.$store.dispatch("products/observeProduct", {
      productId: this.productId
    });
    this.getProductTerms();
  },
  beforeDestroy() {
    this.$store.dispatch("products/unobserveProduct", {
      productId: this.productId
    });
  },
  methods: {
    total(term) {
      const termPrice = term.termPrice[this.currency] || 0;
      return this.$formatCurrency(termPrice, this.currency);
    },
    monthlyTotal(term) {
      const monthlyTermPrice =
        (term.termPrice[this.currency] || 0) / (term.termLength || 1);
      return this.$formatCurrency(monthlyTermPrice, this.currency);
    },
    getProductTerms() {
      const termsRef = collection(
        this.$firestore,
        `products/${this.productId}/terms`
      );
      getDocs(termsRef).then(snapshot => {
        snapshot.forEach(term => {
          this.$set(
            this.productTerms,
            term.id,
            this.$_.merge({}, term.data(), {
              _termId: term.id,
              _productId: this.productId
            })
          );
        });
        this.loading = false;
      });
    },
    addEditTerm(productTerm) {
      const modal = this.$modal.open({
        component: () => import("@shared/product/_productTermModal"),
        parent: this,
        width: 840,
        hasModalCard: true,
        canCancel: ["escape", "outside"],
        props: {
          productId: this.productId,
          productTermId: this.$_.get(productTerm, "_termId", null),
          editedProductTerm: this.$_.omit(productTerm, [
            "_termId",
            "_productId"
          ])
        },
        events: {
          success: () => {
            modal.close();
            this.getProductTerms();
          }
        }
      });
    }
  }
};
</script>
